import './glide';
import './jquery';
import './jquery.dotimeout';
import 'parsleyjs';
import './parsley-fr';
import './parsley-nl';
import '@popperjs/core';
/** Exposing "tippy" globally as it is used by some blade components */
import tippy from 'tippy.js';
window.tippy = tippy;
